<template>
  <div class="main">
    <div class="box center mb48" style="justify-content: space-between">
      <div class="box center">
        <div class="search box center">
          <span class="sp">设置状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search box center">
          <span class="sp">设备编号</span>
          <el-input v-model="searchTxt" placeholder="请输入测点编号"></el-input>
          <!-- <el-button @click="changeValue" class="mlbtn">查询</el-button> -->
        </div>
        <div class="search box center">
          <span class="sp">数据类型</span>
          <el-select v-model="typeValue" placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search box center">
          <!-- <span class="sp">设备编号</span> -->
          <!-- <el-input v-model="searchTxt" placeholder="请输入测点编号"></el-input> -->
          <el-button @click="changeValue" class="mlbtn">查询</el-button>
        </div>
      </div>
      <el-button
        @click="batchSet"
        class="mlbtn"
        :class="!myOption ? 'disable_icon' : ''"
        :disabled="!multipleSelection.length"
        >批量设置</el-button
      >
    </div>
    <template v-if="tableData.length">
      <el-table
        :row-class-name="tableRowClassName"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData.length"
        :row-key="getRowKey"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :height="tableHeight"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column
          align="center"
          prop="target.code"
          label="测点编号"
          class="fs14"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="target.typeName"
          label="监测指标"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="target.staticDynamicSelected"
          label="数据类型"
        >
          <template
            slot-scope="scope"
            v-if="scope.row.target && scope.row.target.staticDynamicSelected"
            >{{
              scope.row.target.staticDynamicSelected | staticDynamic
            }}</template
          >
          <template slot-scope="scope" v-else>{{ staticDynamic }}</template>
        </el-table-column>
        <!-- <el-table-column prop="patchSelected" label="缺失数据补齐">
                 <template slot-scope="scope">{{scope.row.patchSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="outlierSelected" label="异常数据去除">
               <template slot-scope="scope">{{scope.row.outlierSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="denoisingSelected" label="消噪处理">
                 <template slot-scope="scope">{{scope.row.denoisingSelected | trueFalse}}</template>
      </el-table-column> -->
        <el-table-column
          align="center"
          prop="baselineSelected"
          label="基线校正"
        >
          <template slot-scope="scope">{{
            scope.row.baselineSelected | trueFalse
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="staticDynamicSeparationSelected"
          label="动静分离"
        >
          <template slot-scope="scope">{{
            scope.row.staticDynamicSeparationSelected | trueFalse
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="操作">
          <template slot-scope="scope">
            <span
              class="operate-txt"
              @click="go(scope.row.target.id)"
              :class="!myOption ? 'disable_icon' : ''"
              >设置</span
            >
          </template>
        </el-table-column>
        <!-- <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template> -->
        <!-- <template slot="empty" class="imgBox">
          <div>
            <img src="../../assets/images/noData.png" alt="" />
            <span>暂无数据</span>
          </div>
        </template> -->
      </el-table>
      <common-pagination
        v-show="tableData.length > 0"
        :pageSize="pageSize"
        class="pageMain"
        :total="total"
        :currentPage="currentPage"
        @handleCurrentChange="handleCurrentChange"
      />
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import CommonPagination from "../../components/Pagination";
import upload from "@/assets/js/upload.js";
export default {
  name: "documentList",
  components: {
    CommonPagination,
  },
  data() {
    return {
      loading: "",
      projectId: "",
      status: "", //1:已处理，2:未处理
      searchTxt: "", //测点编号
      options: [
        { label: "全部", value: "" },
        { label: "已设置", value: 1 },
        { label: "未设置", value: 2 },
      ],
      typeOptions: [
        { label: "全部", value: "" },
        { label: "静态效应", value: 1 },
        { label: "动态效应", value: 2 },
        { label: "振动响应", value: 3 },
      ],
      typeValue: "",
      value: "", //选择
      tableData: [{}],
      pageSize: 10,
      currentPage: 1,
      total: 1,
      multipleSelection: [],
      totalMultipleSelection: [],
      pathId: "",
      isTrue: "",
      tableHeight:0
    };
  },
  created() {
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
    this.pathId = sessionStorage.getItem("zi");
    this.projectId = sessionStorage.getItem("projectId");
    sessionStorage.setItem("multipleSelection", JSON.stringify([]));
    // this.isTrue = sessionStorage.getItem('isTrue');
    this.isTrue = true;

    this.currentPage = Number(this.$route.query.currentPage) || 1;
    this.status = Number(this.$route.query.status) || "";
    this.value = this.status;
    this.searchTxt = this.$route.query.searchTxt || "";
    this.typeValue = Number(this.$route.query.typeValue) || "";

    console.log("页面切换", this.$store, this.$route);

    // this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue, t: Date.now() } });
    let arr = this.$store.state.breadcrumbList;
    if (arr.length == 2) {
      arr[1].path = `/settings?currentPage=${this.currentPage}&status=${this.status}&searchTxt=${this.searchTxt}&typeValue=${this.typeValue}`;
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      this.type = to.query.type;
      // console.log(this.type)
    },
  },
  mounted() {
    this.getDataList();
    // this.operate();
  },
  methods: {
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      console.log("val", val);
      // this.toggleSelection(val);
      this.multipleSelection = val;

      console.log("this.multipleSelection", this.multipleSelection);
    },
    toggleSelection(rows) {
      if (rows.length) {
        console.log("row", rows);
        this.tableData.forEach((itm, idx) => {
          rows.forEach((row) => {
            if (row.id == itm.id) {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[idx],
                true
              );
            }

            // this.$refs.multipleTable.setCurrentRow(row);
          });
        });
      } else {
        console.log("rows2222222", rows);
        this.$refs.multipleTable.clearSelection();
      }
    },
    batchSet() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("this.multipleSelection", this.multipleSelection);
      let n = this.multipleSelection.length;

      for (let i = 0; i < n; i++) {
        if (
          this.multipleSelection[0].target.staticDynamicSelected !=
          this.multipleSelection[i].target.staticDynamicSelected
        ) {
          return this.$message.error("请选择同种数据类型测点!");
        }
      }

      this.go(this.multipleSelection[0].target.id);
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    //判断是否可操作
    // operate(){
    //   this.$axios.get(`${this.baseURL}auth/authPermission/user/operation?id=${this.pathId}`).then(res => {
    //         console.log('是否可操作', res.data.data);
    //         this.isTrue = res.data.data;
    //       });
    // },

    getDataList() {
      this.$axios
        .get(
          `${this.baseURL}setttings/settingsDataClean/${this.currentPage}/${this.pageSize}?projectId=${this.projectId}&status=${this.status}&code=${this.searchTxt}&manage=${this.typeValue}`
        )
        .then((res) => {
          console.log("数据处理列表qqqqqqqqq", res);
          this.tableData = JSON.parse(JSON.stringify(res.data.data.records));
          this.total = res.data.data.total;

          if (
            this.total &&
            Math.ceil(this.total / this.pageSize) < this.currentPage
          ) {
            this.currentPage = Math.ceil(this.total / this.pageSize);

            this.$router.push({
              path: "/settings",
              query: {
                currentPage: this.currentPage,
                status: this.status,
                searchTxt: this.searchTxt,
                typeValue: this.typeValue,
                t: Date.now(),
              },
            });
          }

          this.$nextTick(() => {
            let arr = this.totalMultipleSelection[this.currentPage - 1] || [];
            console.log(
              "分页切换arr ",
              arr,
              " this.totalMultipleSelection",
              this.totalMultipleSelection
            );
            if (arr.length) {
              this.toggleSelection(arr);
            }
          });
          // this.$forceUpdate();
        });
    },
    changeValue() {
      this.status = this.value;
      this.currentPage = 1;

      console.log("点击查询", this.currentPage);

      this.$router.push({
        path: "/settings",
        query: {
          currentPage: this.currentPage,
          status: this.status,
          searchTxt: this.searchTxt,
          typeValue: this.typeValue,
          t: Date.now(),
        },
      });

      this.getDataList();
    },
    go(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("id", id);
      // if(this.isTrue != "true"){
      //   return;
      // }

      // console.log('id', id)
      sessionStorage.setItem("handleId", id);
      // sessionStorage.setItem('handlePageId',this.currentPage)

      // this.$router.push({
      //       path:'/settingData',
      //       query:{
      //         id,
      //         type:'edit'
      //       }
      // });
      sessionStorage.setItem(
        "multipleSelection",
        JSON.stringify(this.multipleSelection)
      );
      this.$router.push(
        `/settingData/${id}?currentPage=${this.currentPage}&status=${this.status}&searchTxt=${this.searchTxt}&typeValue=${this.typeValue}`
      );
    },

    handleCurrentChange(val) {
      console.log("fenye", val);
      this.totalMultipleSelection[this.currentPage - 1] =
        JSON.parse(JSON.stringify(this.multipleSelection)) || [];
      console.log("this.totalMultipleSelection", this.totalMultipleSelection);
      this.currentPage = val;
      this.$router.push({
        path: "/settings",
        query: {
          currentPage: this.currentPage,
          status: this.status,
          searchTxt: this.searchTxt,
          typeValue: this.typeValue,
          t: Date.now(),
        },
      });
      this.getDataList();
    },

    resetData() {
      //重置
      this.handleCurrentChange(1);
      this.searchTxt = "";
      // this.getDataList()
    },
    getSize(){
        this.tableHeight = window.innerHeight - 355 
    }
  },
};
</script>

<style scoped lang="scss">
.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.main {
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 38px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

.sp {
  width: 94px !important;
}

.mlbtn {
  margin-left: 20px;
}

::v-deep button.el-button.is-disabled,
::v-deep button.el-button.is-disabled:focus,
::v-deep button.el-button.is-disabled:hover {
  background-color: #434f6a !important;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
